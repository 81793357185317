.image-zoom-container {
    overflow: hidden;
    position: relative;
}

.image-zoom-container img {
    transition: transform 0.3s ease;
}

.image-zoom-container.zoomed img {
    transform: scale(1.2);
}