.settings {
    width: 50%;
    border-radius: 10px;
    padding: 3%;
    margin-left: 25%;
    font-weight: 550;
    margin-bottom: 5%;
    padding-bottom: 5%;
    font-size: 22px;
    border: 2px solid lightgrey;
    padding-top: 4%;
}

.timings input {
    width: 60%;
}

.location input {
    width: 60%;
}

.phonenumber input {
    border: 1px solid lightgray;
    border-radius: 5px;
}

.email input {
    border: 1px solid lightgray;
    border-radius: 5px;
}

.name input {
    width: 60%;
    border-radius: 5px;
}

form {
    font-size: 20px;
}

.settings input {
    margin-top: 5%;
    margin-left: 1%;
}

.owner {
    background-color: dodgerblue;
    color: white;
    padding: 2%;
    border-radius: 20px;
    margin-top: 4%;
    width: 30%;
    transition: 0.5s;
    margin-left: 33%;
}

.owner:hover {
    background-color: lightblue;
}

.submitbutton {
    background-color: dodgerblue;
    color: white;
    padding: 2%;
    border-radius: 20px;
    margin-top: 4%;
    width: 30%;
    transition: 0.5s;
    margin-left: 33%;
}

.submitbutton:hover {
    background-color: lightblue;
}

.phonenumber input {
    padding: 1%;
}

.email input {
    padding: 1%;
}

.removeOwnerButton {
    background-color: dodgerblue;
    padding: 2%;
    border-radius: 20px;
    color: white;
    margin-top: 6%;
    margin-left: 33%;
    width: 30%;
    transition: 0.5s;
}

.removeOwnerButton:hover {
    background-color: lightblue;
}

@media screen and (max-width : 768px) {
    .settings {
        font-size: 12px;
        width: 90%;
        margin-left: 5%;
    }

    .removeOwnerButton {
        width: 60%;
        margin-left: 20%;
    }

    .submitbutton {
        width: 60%;
        margin-left: 20%;
    }

    .owner {
        width: 60%;
        margin-left: 20%;
    }
}