.Home {
    text-align: center;
}

.Home-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .Home-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.Home-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.Home-link {
    color: #61dafb;
}

@keyframes Home-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

body {
    overflow-x: hidden;
}




nav {
    background-color: #BABABA;
    font-weight: bold;
    color: white;
    display: flex !important;
    flex-direction: row !important;
    padding-right: 20px;
    width: 100%;
    justify-content: space-between;
    height: 70px;
}

h4 {
    margin-left: 5%;
    margin-top: 22px !important;
    font-weight: bold;
}

nav ul {
    list-style: none;
    text-align: center;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

nav ul li {
    display: inline-block;
    position: relative;
    text-align: center;
    width: 80px;
    margin-left: 0;
}

nav ul li ul {
    width: auto;
    background: #BABABA;
    text-align: center;
}

nav ul li a {
    display: block;
    text-decoration: none;
    color: #fff;
    background: #BABABA;
    font-size: 15px;
    padding: 20px 25px;
}

nav ul li ul.dropdown li {
    display: block;
    width: 100%;
    background-color: #BABABA;
    margin-right: -120%;
}

ul li ul.dropdown {
    width: 100%;
    background: #BABABA;
    position: absolute;
    z-index: 999;
    display: none;
}

ul li a:hover {
    background: lightcoral;
    width: 100%;
    height: max-content;
    text-decoration: none;
    color: white;
}

ul li:hover ul.dropdown {
    display: block;
    width: 200%;
}

h1 {
    color: white;
    font-size: 25px;
}

.user-info {
    display: flex;
    align-items: center;
}

.user-info img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}

#contents {
    display: flex;
}

@media (max-width: 768px) {
    #contents {
        flex-direction: column;
    }
}



#revenue {
    padding: 0;
    border: 1.5px solid black;
    margin: 3%;
    border-radius: 10px;
    height: 48px;
    width: 25%;
    display: flex;
    font-size: 2px;
}

#revenue .symbol {
    padding: 0;
    margin: 0;
    text-align: left;
    margin-right: 30%;
    vertical-align: middle;
    background-color: purple;
    height: 50px;
    width: 48px;
    border-radius: 0 200px 200px 0;
    line-height: 50px;
    color: white;
    text-align: center;
    font-size: 20px;
}

#revenue .amount {
    text-align: right;
    padding-right: 3px;
}

#orders {
    padding: 0;
    border: 1.5px solid black;
    margin: 3%;
    border-radius: 10px;
    height: 50px;
    width: 25%;
    display: flex;
    font-size: 10px;
}

#orders .symbol {
    padding: 0;
    margin: 0;
    text-align: left;
    margin-right: 40%;
    vertical-align: middle;
    background-color: purple;
    color: black;
    height: 50px;
    width: 48px;
    border-radius: 0 200px 200px 0;
    line-height: 50px;
    padding: 13px;
    text-align: center;
}

#orders .quantity {
    text-align: right;
}

#reviews {
    padding: 0;
    border: 1.5px solid black;
    margin: 3%;
    border-radius: 10px;
    height: 50px;
    width: 25%;
    display: flex;
    font-size: 10px;
}

#reviews .symbol {
    padding: 0;
    margin: 0;
    text-align: left;
    margin-right: 30%;
    vertical-align: middle;
    background-color: purple;
    height: 50px;
    width: 48px;
    border-radius: 0 200px 200px 0;
    line-height: 50px;
    padding: 13px;
    text-align: center;
}

#reviews .pending {
    text-align: right;
}

#customers {
    padding: 0;
    border: 1.5px solid black;
    margin: 3%;
    border-radius: 10px;
    height: 50px;
    width: 25%;
    display: flex;
    font-size: 10px;
}

#customers .symbol {
    padding: 0;
    margin: 0;
    text-align: left;
    margin-right: 30%;
    vertical-align: middle;
    background-color: purple;
    height: 50px;
    width: 48px;
    border-radius: 0 200px 200px 0;
    line-height: 50px;
    padding: 13px;
    text-align: center;
}

#customers .quantity {
    text-align: right;
}

#history {
    border: 1.5px solid black;
    margin: 40px;
    padding: 10px;
    border-radius: 10px;
    width: 44%;
    height: 300px;
    font-weight: 1000;
    margin-left: 3%;
}

#history1 {
    border: 1.5px solid black;
    margin: 40px;
    padding: 10px;
    border-radius: 10px;
    width: 80%;
    height: 300px;
    font-weight: 1000;
}


.chartContainer {
    width: 100%;
    /* This will make the chart take 100% width of its parent */
}

/* Adjust chart size for mobile devices */
@media (max-width: 768px) {
    .chartContainer {
        width: 100%;
        /* Adjust width for smaller screens */
        height: 200px;
        /* Adjust height for smaller screens */
        margin-left: -5%;
    }
}


#penord {
    border: 1.5px solid black;
    margin: 40px;
    padding: 10px;
    border-radius: 10px;
    width: 44%;
    height: 300px;
    font-weight: 1000;
    margin-left: 3%;
}

@media (max-width: 767px) {
    #penord {
        margin-left: 10%;
    }
}


.pendingReviews {
    box-sizing: border-box;
    width: 19%;
    height: 848px;
    display: flex;
    background-color: #ffffff;
    overflow: visible;
    position: absolute;
    border-radius: 0px 0px 20px 20px;
    border: 1px solid #000000;
    margin-top: 2.5%;
    flex-direction: column;
}

.review1 {
    display: flex;
    flex-direction: row;
    margin: 20px;
    margin-left: 25%;
    gap: 30px;

}

.customer {
    display: flex;
    margin: 20px;
    margin-left: 25%;
    gap: 30px;
}

@media (max-width: 768px) {
    .pendingReviews {
        display: none;
    }
}


@media (max-width: 768px) {

    nav ul {
        flex-direction: column;
        align-items: center;
    }

    nav ul li {
        margin: 5px 0;
        width: 50px;
    }

    #history,
    #penord {
        width: 80%;
    }

    #revenue,
    #orders,
    #reviews,
    #customers {
        width: 75%;
        margin-left: 50px;
    }

    #revenue .symbol,
    #orders .symbol,
    #reviews .symbol,
    #customers .symbol {
        width: 14px;
        font-size: 14px;
        margin-right: 5%;
    }

    #revenue .symbol {
        color: purple;
        width: 40px;
    }

    #revenue .amount,
    #orders .quantity,
    #reviews .pending,
    #customers .quantity {
        padding-right: 5px;
        margin-top: 8px;
        font-size: 10px;
        font-weight: bold;
    }

    h4 {
        font-size: 12px;
    }

    nav ul li a {
        padding: 15px 10px;
        font-size: 10px;
    }

    .user-info img {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-right: 5px;
    }

    .user-info {
        font-size: 10px;
        margin-top: -2px;
    }

    nav {
        height: 60px;
    }
}

.store {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}