.containermain {
    width: 100%;
    height: 100%;
    display: flex;
    /* Light Blue */
}

.loginText {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 8%;
    gap: 40px;
}

@media (max-width:768px) {
    .loginText {
        width: 100%;
        margin-top: 50%;
        gap: 20px;
    }
}

.welcomeText1 {
    font-weight: bold;
    font-size: 42px;
}

.welcomeText2 {
    font-size: 20px;
}

.loginImage {
    width: 50%;
}

@media (max-width:768px) {
    .loginImage {
        display: none;
    }
}

.custom-box {
    width: 300px;
    /* Default width for mobile */
}

@media (min-width: 768px) {
    .custom-box {
        width: 500px;
        /* Adjusted width for larger screens */
        max-width: 100%;
        /* Ensure it doesn't exceed the screen width */
    }
}

.Forgot {
    display: flex;
    justify-content: flex-end;
}

.Signup {
    display: flex;
    justify-content: center;
    margin-top: 5%;
}