.addProduct {
    height: 100vh;
    width: 100vw;
}

.addProduct1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    gap: 30px;
}

@media (max-width:767px) {
    .addProduct1 {
        display: flex;
        align-items: center;
        justify-content: center;
    }

}



.addProduct2 {
    display: flex;
    flex-direction: row;
    gap: 100px;
}

@media (max-width:767px) {
    .addProduct2 {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}


.addProduct3 {
    display: flex;
    flex-direction: row;
    gap: 100px;
}

@media (max-width:767px) {
    .addProduct3 {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}


.frame {
    height: 450px;
    width: 787px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    justify-content: center;
}

@media (max-width: 767px) {
    .frame {
        height: 200px;
        width: 400px;
        margin-top: 300px;

    }
}


.frame1 {
    height: 450px;
    width: 476px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
}

@media (max-width: 767px) {
    .frame1 {
        height: 400px;
        width: 400px;

    }
}


.frame2 {
    height: 116px;
    width: 787px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 767px) {
    .frame2 {
        height: 200px;
        width: 400px;

    }
}


.frame3 {
    height: 116px;
    width: 476px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

@media (max-width: 767px) {
    .frame3 {
        height: 200px;
        width: 400px;
        border-radius: 10px;

    }
}



.custom-textfield {
    width: 500px;
}

@media (max-width: 768px) {
    .custom-textfield {
        width: 300px;
        /* Adjust for smaller screens */
        height: 50px;
        /* Adjust for smaller screens */
    }
}

.customTextInput {
    width: 500px;
    height: 300px;
    padding: 10px;
    /* Add padding to create space between text and border */
    font-size: 16px;
    /* Adjust the font size as needed */
    border: 0.5px solid #000;
    /* Add a border */
}

.customTextInput:focus {
    outline: none;
    /* Remove the outline when focused */
}

@media (max-width:768px) {
    .customTextInput {
        height: 50px;
        width: 300px
    }

}

.custom-textfield1 {
    width: 348px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 30px;
}

.frame5 {
    display: flex;
    justify-content: space-between;
}

.column {
    flex: 1;
    margin-left: 10px;
    /* Adjust the spacing between columns */
    gap: 35px;

}

.custom-textfield2 {
    width: 90%;
    gap: 20px;
    /* Adjust the spacing between text inputs */
}