.main-container {
  display: flex;
  height: 100vh;
  /* Adjust to your layout requirements */
  overflow: hidden;
}



.image1 {
  background-color: #6E63A5;
  align-items: center;
  flex: 1;
  height: 100vh;

}

.image2 {
  background-color: white;
  align-items: center;
  flex: 1;
  height: 100vh;
}


.inputfields {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 60px;
}

/* Base styles for all screen sizes */
.text-large {
  font-size: 33px;
  margin-top: 10px;
}

.text-small {
  font-size: 16px;
}

/* Adjust styles for smaller screens like mobile */
@media (max-width: 767px) {
  .text-large {
    font-size: 24px;
    /* Adjust font size for mobile */
  }

  .text-small {
    font-size: 13px;
    /* Adjust font size for mobile */
  }
}


.namefield1 {
  display: flex;
  flex-direction: row;
  gap: 40px;
  margin: 30px;
}

@media (max-width: 767px) {
  .namefield1 {
    flex-direction: column;
    gap: 30px;
    width: 100%;
    align-items: center;
  }
}

.businessName {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
}

.business {
  width: 480px
}

@media (max-width: 767px) {
  .business {
    width: 100%;
  }
}

.email {
  width: 480px
}

@media (max-width: 767px) {
  .email {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .MuiFormControl-root {
    width: 100%;
    /* Set the desired width for mobile screens */
  }
}

.signup {
  width: 480px;
  background-color: #6E63A5;
}

@media (max-width:767px) {
  .signup {
    width: 100%;
  }
}

.SignIn {
  width: 240px;
  margin-left: 250px;
  cursor: pointer;
}

@media (max-width:767px) {
  .SignIn {
    width: 100%;
    margin-left: 0;
  }
}