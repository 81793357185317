.select-container {
    width: 348px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-select {
    width: 100%;
    height: 40px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.custom-select:focus {
    outline: none;
    border-color: #0066cc;
}